"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileService = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const constants_1 = require("./constants");
const bff_service_1 = require("./bff-service");
const applicationDataService_1 = require("./applicationDataService");
class UserProfileService {
    constructor() {
        this._allAvatars = [];
    }
    static get Instance() {
        if (!UserProfileService._instance) {
            UserProfileService._instance = new UserProfileService();
        }
        return UserProfileService._instance;
    }
    getRequestHeaders() {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
    }
    /**
     * Retrieves user profile settings for a given user ID.
     * @param userId - The ID of the user whose profile settings are being retrieved.
     * @param key - (Optional) The specific key of the profile setting to retrieve.
     * @returns A Promise that resolves with the user profile settings.
     */
    getUserProfileSettings(userId, key) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + (0, constants_1.USER_PROFILE_SETTINGS)(userId) + (key ? `?key=${key}` : '');
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: this.getRequestHeaders(),
            });
            res = yield this._handleErrors(res);
            return yield res.json();
        });
    }
    /**
     * Updates the user profile settings for a given user ID.
     * @param userId - The ID of the user whose profile settings are being updated.
     * @param profileSettings - The updated profile settings.
     * @returns A Promise that resolves to a boolean indicating whether the update was successful.
     */
    updateUserProfileSettings(userId, profileSettings) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + (0, constants_1.USER_PROFILE_SETTINGS)(userId);
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "put",
                body: JSON.stringify(profileSettings),
                headers: this.getRequestHeaders(),
            });
            res = yield this._handleErrors(res);
            return res ? yield res.json() : null;
        });
    }
    /**
     * Resets the user profile settings for the given key.
     * @param userId The ID of the user whose profile settings need to be reset.
     * @param key - (Optional) The specific key of the profile setting to reset.
     * @returns A Promise that resolves to a boolean indicating whether the reset was successful or not.
     */
    resetUserProfileSettings(userId, key) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + (0, constants_1.USER_PROFILE_SETTINGS_RESET)(userId) + (key ? `?key=${key}` : '');
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "put",
                headers: this.getRequestHeaders(),
            });
            res = yield this._handleErrors(res);
            return res ? yield res.json() : null;
        });
    }
    /**
     * uploads profile picture for the given user ID.
     * @param userId The ID of the user whose profile picture need to be set.
     * @param formData  profile picture image.
     */
    uploadProfilePicture(userId, formData) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + (0, constants_1.USER_PROFILE_SETTINGS_PROFILE_PICTURE)(userId);
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "PATCH",
                headers: {},
                body: formData
            });
            res = yield this._handleErrors(res);
            return res ? yield res.json() : null;
        });
    }
    /**
     * Handles errors for API calls.
     * @param {Response} response - The response object from the API call.
     * @returns {Promise<any>} - Returns the response object if it is ok.
     * @throws {Error} - Throws an error object if the response is not ok.
     */
    _handleErrors(response) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!response.ok) {
                let errorMsg = '';
                try {
                    const errObj = yield response.json();
                    console.error('Error during API calls', errObj);
                    errorMsg = (errObj === null || errObj === void 0 ? void 0 : errObj.message) || '';
                }
                catch (e) {
                    console.log('handleErrors: Error during parsing JSON');
                }
                const error = {
                    name: response.statusText,
                    message: errorMsg,
                    stack: (_a = response.status) === null || _a === void 0 ? void 0 : _a.toString()
                };
                throw error;
            }
            return response;
        });
    }
    /**
     * Retrieves all avatars.
     * @returns A Promise that resolves with the avatar id and image.
     */
    getAllAvatar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._allAvatars.length > 0)
                return this._allAvatars;
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + (0, constants_1.ALL_AVATAR_INFO)();
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: this.getRequestHeaders(),
            });
            res = yield this._handleErrors(res);
            const avatarInfo = yield res.json();
            this._allAvatars = avatarInfo === null || avatarInfo === void 0 ? void 0 : avatarInfo.avatars;
            return this._allAvatars;
        });
    }
    /**
     * Switch to new role.
     * @Input Takes application code and new role id to switch to new role.
     */
    switchRole(roleId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + (0, constants_1.SWITCH_ROLE)(roleId);
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: this.getRequestHeaders(),
            });
            res = yield this._handleErrors(res);
            return yield res.json();
        });
    }
}
exports.UserProfileService = UserProfileService;
