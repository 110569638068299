"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationHub = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This file is a interface to notifications Service
 */
const main_1 = require("../main");
const constants_1 = require("../constants");
const uuid_1 = require("uuid");
const bff_service_1 = require("../bff-service");
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const signalR = __importStar(require("../dependencies/signalr/signalr"));
class NotificationHub {
    constructor() {
        this.subscribers = [];
        this.signalrConnection = null;
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    /**
     * async init method to establish signal R connection
     */
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.signalrConnection &&
                    (this.signalrConnection.state ===
                        signalR.HubConnectionState.Connected ||
                        this.signalrConnection.state ===
                            signalR.HubConnectionState.Connecting ||
                        this.signalrConnection.state ===
                            signalR.HubConnectionState.Reconnecting)) {
                    console.log("connection status", this.signalrConnection.state);
                    return;
                }
                yield this.setUpSignalR();
            }
            catch (error) {
                console.error("Signal R setup failed", error);
            }
        });
    }
    /**
     * To setup SignalR connection
     */
    setUpSignalR() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.signalrConnection = new signalR.HubConnectionBuilder()
                    .withUrl(main_1.ApplicationDataService.Instance.getApiURL() + constants_1.SIGNALR_ENDPOINT + '?applicationCode=' + main_1.AuthService.Instance.getApplicationCode(), {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                })
                    .configureLogging(signalR.LogLevel.Debug)
                    .withAutomaticReconnect()
                    .build();
                if (this.signalrConnection) {
                    yield this.startSignalrConnection();
                }
            }
            catch (error) {
                console.error("Signal R connection setup failed", error);
                setTimeout(() => { this.init(); }, 5000);
            }
        });
    }
    /**
     * To Start signalR connection
     */
    startSignalrConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.signalrConnection && this.signalrConnection.state === signalR.HubConnectionState.Disconnected) {
                    yield this.signalrConnection.start();
                    this.registerSignalrEventListeners();
                    this.reSubscribeTopics();
                }
                else {
                    console.log("Signalr connection", this.signalrConnection, "Signalr state", this.signalrConnection.state);
                }
            }
            catch (err) {
                console.error("Signal R connection failed", err);
                setTimeout(() => { this.startSignalrConnection(); }, 5000); // try reconnect after 5 seconds
            }
        });
    }
    /**
     * To register required signalR listener
     */
    registerSignalrEventListeners() {
        this.signalrConnection.onclose(() => __awaiter(this, void 0, void 0, function* () {
            yield this.startSignalrConnection();
        }));
        this.signalrConnection.on(constants_1.RECEIVED_MESSAGE, (messages) => {
            console.log("ReceivedMessage::" + JSON.stringify(messages));
            this.notifyListener(messages);
        });
        this.signalrConnection.onreconnected(() => __awaiter(this, void 0, void 0, function* () {
            console.log("on onreconnected");
            yield this.reSubscribeTopics();
        }));
    }
    reSubscribeTopics() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.signalrConnection &&
                    this.signalrConnection.state === signalR.HubConnectionState.Connected) {
                    console.log("reSubscribeTopics");
                    const uniqueSub = [
                        ...new Set(this.subscribers.map((item) => item.topic)),
                    ];
                    for (const topic of uniqueSub) {
                        console.log("invokig topic", topic);
                        const status = yield this.subscribeToSignalR(topic);
                        this.updateSubscribtionList(topic, status);
                    }
                }
            }
            catch (err) {
                console.log(`reSubscribeTopics failed ${err}`);
            }
        });
    }
    /**
     * To notify all respective subscribers
     * @param notification Payload recieved from signalR
     */
    notifyListener(notification) {
        const recieverTopic = notification.topic || notification.recipientAddress;
        const payload = notification.topic ? notification.message : notification;
        this.subscribers
            .filter((e) => e.topic === recieverTopic)
            .forEach((e) => {
            try {
                e.callback(payload);
            }
            catch (e) {
                console.log("Error ", e);
            }
        });
    }
    /**
     * To add client listener
     * @param {string} topic  mailbox/topic which client want to listen
     * @param callback listener method, will recieve message payload
     * @returns
     */
    subscribe(topic, callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const subscribedObj = this.subscribers.find((e) => e.topic === topic && e.callback === callback);
            if (subscribedObj && !subscribedObj.subscribeStatus) {
                const status = yield this.subscribeToSignalR(topic);
                subscribedObj.subscribeStatus = status;
                return subscribedObj.subscriptionID;
            }
            else if (subscribedObj && subscribedObj.subscribeStatus) {
                return subscribedObj.subscriptionID;
            }
            else {
                const subscriptionID = (0, uuid_1.v4)();
                const sub = this.subscribers.find((e) => e.topic === topic);
                let status = false;
                if (sub && sub.subscribeStatus) {
                    status = true;
                }
                else {
                    // new topic or existing topic with subscribeStatus as false
                    status = yield this.subscribeToSignalR(topic);
                }
                this.subscribers.push({
                    topic,
                    callback,
                    subscribeStatus: status,
                    subscriptionID,
                });
                return subscriptionID;
            }
        });
    }
    /**
     * subscribeToSignalR
     * @param topic
     * @returns
     */
    subscribeToSignalR(topic) {
        return __awaiter(this, void 0, void 0, function* () {
            let subStatus = false;
            if (this.signalrConnection &&
                this.signalrConnection.state === signalR.HubConnectionState.Connected) {
                try {
                    yield this.signalrConnection.invoke(constants_1.SUBSCRIBE_SIGNALR, topic);
                    subStatus = true;
                }
                catch (e) {
                    subStatus = false;
                }
            }
            return subStatus;
        });
    }
    /**
     * To update SubscribtionList
     * @param topic
     * @param status
     */
    updateSubscribtionList(topic, status) {
        this.subscribers.forEach((sub) => {
            if (sub.topic === topic) {
                sub.subscribeStatus = status;
            }
        });
    }
    /**
     * To getStatusById
     * @param subscriptionID Id
     * @return status
     */
    getStatusById(id) {
        const item = this.subscribers.find(sub => sub.subscriptionID === id);
        return item === null || item === void 0 ? void 0 : item.subscribeStatus;
    }
    /**
     * To remove subscribtion
     * @param subscriptionID
     */
    unsubscribe(subscriptionID) {
        return __awaiter(this, void 0, void 0, function* () {
            const subscribedObj = this.subscribers.find((sub) => sub.subscriptionID === subscriptionID);
            try {
                if (subscribedObj) {
                    const subcribedToSameAddress = this.subscribers.filter((s) => s.topic === subscribedObj.topic);
                    if (subcribedToSameAddress.length === 1) {
                        yield this.signalrConnection.invoke(constants_1.UNSUBSCRIBE_SIGNALR, subscribedObj.topic);
                    }
                    this.subscribers = this.subscribers.filter((sub) => sub.subscriptionID !== subscriptionID);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    /**
     * To get notifications from notification sever
     * @param {string} mailBoxAddress - mail box address
     * @param {Array<NotificationType>} types - types of notifications included in result
     * @param {string} marker - marker id, to get notification from markerid onwards
     * @param {string} limit - no of notification
     */
    getNotifications(mailBoxAddress, types, state, marker, limit, severity = [], applications = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = main_1.ApplicationDataService.Instance.getApiURL() +
                (0, constants_1.GET_NOTIFICATIONS_ENDPOINT)(mailBoxAddress);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "post",
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
                body: JSON.stringify({
                    types: types,
                    marker: marker,
                    state: state,
                    limit: limit,
                    senderAddresses: applications,
                    payload: {
                        severity,
                    },
                }),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    /**
     * To update notification state
     * @param {NotificationState} action read/unread/clear
     * @param {string} mailBoxAddress - Address of mailbox
     * @param {string} messageId - notification id
     */
    updateState(action, mailBoxAddress, messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = main_1.ApplicationDataService.Instance.getApiURL() +
                (0, constants_1.UPDATE_NOTIFICATIONS_ENDPOINT)(mailBoxAddress, messageId);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "put",
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
                body: JSON.stringify({
                    state: action,
                }),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    /**
     * To send notification
     * @param {string} senderMailBoxAddress sender address
     * @param {Object} messagePayload - message in Json format
     * @param {NotificationType} type - type of notification
     * @param {Array} recepients array of recepients address
     */
    sendNotification(senderMailBoxAddress, messagePayload, type, recepients) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = main_1.ApplicationDataService.Instance.getApiURL() +
                (0, constants_1.SEND_MESSAGE_ENDPOINT)(senderMailBoxAddress);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "post",
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
                body: JSON.stringify({
                    recipientAddresses: recepients,
                    type: type,
                    payload: messagePayload,
                }),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    /**
     * To Delete message by id
     * @param {string} mailBoxAddress address of mailbox
     * @param {string} messageId The ID of the message to delete
     */
    deleteNotification(mailBoxAddress, messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = main_1.ApplicationDataService.Instance.getApiURL() +
                (0, constants_1.DELETE_MESSAGE_ENDPOINT)(mailBoxAddress, messageId);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "delete",
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    /**
     * To get unread count
     * @param {string} mailBoxAddress, address of mailbox
     * @param {NotificationType} type type of notification, it's enum NotificationType
     * @returns
     */
    getUnreadCount(mailBoxAddress, type = constants_1.NotificationType.Luna) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = main_1.ApplicationDataService.Instance.getApiURL() +
                (0, constants_1.UNREAD_COUNT_ENDPOINT)(mailBoxAddress) +
                "?type=" +
                type;
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
}
exports.NotificationHub = NotificationHub;
