"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendBffPrefix = void 0;
const constants_1 = require("../constants");
function appendBffPrefix(url) {
    const components = url.split("/");
    const newUrl = components[2] + constants_1.BFF_PRIFIX;
    return url.replace(components[2], newUrl);
}
exports.appendBffPrefix = appendBffPrefix;
