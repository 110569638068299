"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const constants_1 = require("./constants");
const applicationDataService_1 = require("./applicationDataService");
const bff_service_1 = require("./bff-service");
const utils_1 = require("./utils");
class AuthService {
    constructor() {
        this._user = { profile: {} };
        this.logoutUrl = "";
        this._applicationCode = "";
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    ensureInitSuccessful() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
            while (Object.keys(this.getClaims()).length === 0) {
                yield this.timeout(100);
            }
            if (Object.keys(this.getClaims()).length) {
                resolve(true);
            }
        }));
    }
    timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    loggedInViaPopup() {
        return window.sessionStorage.getItem(constants_1.FROM_POPUP);
    }
    getClaims() {
        return Object.keys(this._user.profile).length
            ? this._user.profile
            : applicationDataService_1.ApplicationDataService.Instance.getUserProfile();
    }
    getLogoutUrl() {
        const logoutUrl = localStorage.getItem("logoutUrl");
        return logoutUrl;
    }
    logout() {
        console.log("navigating to following url", this.logoutUrl);
        if (this.logoutUrl || this.getLogoutUrl()) {
            window.location.href = this.logoutUrl || this.getLogoutUrl();
        }
    }
    authenticate(url, applicationCode) {
        return __awaiter(this, void 0, void 0, function* () {
            const userDetails = {
                profile: {},
            };
            try {
                this._applicationCode = applicationCode; // set application code
                const response = yield fetch((0, utils_1.appendBffPrefix)(`${applicationDataService_1.ApplicationDataService.Instance.getApiURL()}${"/user"}`), {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "x-csrf": "1",
                        applicationCode: this._applicationCode
                    },
                });
                if (response.status === 200) {
                    const data = yield response.json();
                    this._user.profile = this.generateUser(data);
                    this.logoutUrl = `${applicationDataService_1.ApplicationDataService.Instance.getApiURL()}${this._user.profile["bff:logout_url"]}&applicationcode=${this._applicationCode}&returnUrl=${url.returnUrl ? url.returnUrl : "/"}`;
                    localStorage.setItem("logoutUrl", this.logoutUrl);
                    userDetails.profile = this._user.profile;
                    sessionStorage.setItem("oidc.user:", JSON.stringify(userDetails));
                    return this._user.profile;
                }
                else if (response.status === 401) {
                    const redirectionUrl = url.returnUrl ? url.returnUrl : "/";
                    window.location.href = `${applicationDataService_1.ApplicationDataService.Instance.getApiURL() + constants_1.BFF_PRIFIX}${constants_1.LOGIN}?applicationcode=${this._applicationCode}&returnUrl=${redirectionUrl}`;
                    return null;
                }
                else if (response.status === 403 &&
                    response.headers.has("x-bff-logout") &&
                    response.headers.get("x-bff-logout") === "true") {
                    if (this.logoutUrl || this.getLogoutUrl()) {
                        this.logout();
                    }
                    else {
                        const result = yield response.json();
                        this.logoutUrl = `${applicationDataService_1.ApplicationDataService.Instance.getApiURL()}${result.message}&applicationcode=${this._applicationCode}&returnUrl=${url.returnUrl ? url.returnUrl : "/"}`;
                        this.logout();
                    }
                    return null;
                }
                else {
                    console.log("Server encountered internal error!");
                }
            }
            catch (error) {
                console.log("Error in authentication function", error);
            }
        });
    }
    generateUser(data) {
        const userObj = { luna_permission: [] };
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].type === "luna_permission") {
                    userObj.luna_permission.push(data[i].value);
                }
                else {
                    userObj[data[i].type] = data[i].value;
                }
            }
            return userObj;
        }
        return null;
    }
    getSessionTimeout(slide = true) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield (0, bff_service_1.fetchThroughBff)(`${applicationDataService_1.ApplicationDataService.Instance.getApiURL()}${"/session?slide="}${slide}`);
                const data = yield response.json();
                if (data) {
                    return data.value;
                }
                else {
                    return null;
                }
            }
            catch (error) {
                console.log("Error while getting session timeout", error);
            }
        });
    }
    setApplicationCode(appCode) {
        this._applicationCode = appCode;
    }
    getApplicationCode() {
        return this._applicationCode;
    }
}
exports.AuthService = AuthService;
